/* START reset css */ 

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  color: currentColor;
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font-family: inherit;
  font-size: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

/* END reset css */ 

body {
  --d6-pink: #e63888;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  margin: 0;
  
  & h1,
  & h2,
  & p,
  & a {    
    margin-top: 0;
    position: relative;
  }
}

#root {
  display: grid;
  min-height: 100vh;
}

.app {
  padding-top: 100px;

  &:has(.final) {
    align-content: center;
    padding-top: 0;
  }
  @media screen and (min-width: 900px) and (max-height: 700px) {
    padding-top: 50px;
  }
}

.pink-wrap {
  &::before {
    background-color: var(--d6-pink);
    content: '';
    display: block;
    inset: 0;
    position: fixed;
    transition: scale .8s ease-in-out;
    z-index: 0;
  }
  &.scanning {
    &::before {
      scale: 0;
    }
    .content-step1 {
      opacity: 0;
    }
  }
  &.moveUp{
    &::before {
      z-index: 1;
    }
  }
}

.container {
  color: white;
  margin: 0 auto;
  opacity: 1;
  position: relative;
  transition: opacity .8s;
  width: min(80vw, 1140px);
}

h1 {
  font-size: min(20vw, 380px);
}

.img {
  object-fit: cover;
}

.hidden {
  display: none !important;
}

.home {
  & form {
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 40px;
    max-width: fit-content;
    
    & label {
      grid-column-end: 3;
      grid-column-start: 1;
      grid-row-start: 3;
    }
    @media screen and (min-width:1400px) {
      grid-template-columns: 1fr 60px;
    }
    @media screen and (min-width:1400px) {
      gap: 10px;
      grid-template-columns: 1fr 1fr 60px;
      max-width: none;

      & label {
        grid-column-start: 2;
        grid-row-start: 2;
      }
    }
  }
  & p {
    font-size: 24px;
    margin: 0;

    @media screen and (min-width:900px) {
      font-size: 48px;
    }
    @media screen and (max-width:1400px) {      
      grid-column: 1 / 3;
    }
  }
  & input[type=text] {
    border: 0;
    font-size: 24px;
    padding: 10px;
    width: 100%;

    @media screen and (min-width:900px) {
      font-size: 38px;
      padding: 8px 20px;
    }
  }
  & button {
    appearance: button;
    background: none;
    border: 0;
    color: white;
    cursor: pointer;
    font-size: 30px; 
    margin-top: -5px;

    @media screen and (min-width:900px) {
      font-size: 40px;
    }
  }
}

.dot{
  animation: reveal .5s forwards .5s running;
  opacity: 0;
  position: relative;

  & + .dot {
    animation-delay: .75s;

    & + .dot {
      animation-delay: 1s;
    }
  }
}
.wait .dot {
  animation-play-state: paused;
}

@keyframes reveal {
  to {
    opacity: 1;
  }
}

.greeting {
  font-size: 48px;
  line-height: 100%;
  text-align: right;

  @media screen and (min-width:900px) {
    font-size: 96px;
  }
}
.username {  
  justify-content: end;

  & > div {
    line-height: min(100%, 600px);

    @media screen and (max-height: 700px) {
      line-height: min(100%, 400px)
    }
  }
  @media screen and (min-width:900px) {
    margin-top: -10px;
  }
}
.content-step1 .typed {
  font-size: 26px;
  margin-left: auto;
  margin-top: 2em;
  max-width: 735px;
  width: 100%;
}
.content-step2 .typed {  
  color: white;
  font-size: 48px;
  line-height: 1.3em;
  margin-top: 35vh;
  text-align: center;
  
  @media screen and (min-width:900px) {
    font-size: 60px;
    line-height: 1.3em;
  }
}
img {  
  height: 100%;
  object-fit: cover;  
  width: 100%;

  &.imgScanning {
    inset: 0;
    object-position: 60% 50%;
    position: fixed;
    z-index: -1;
    
    @media screen and (min-width:900px) {
      object-position: right 50%;
    }
  }
}
.circle-wrap {
  &::before {
    background-image: url('./Images/circle.png');
    content: '';
    display: block;
    height: 1080px;
    position: fixed;
    right: 0;    
    scale: .35;
    top: 0;
    translate: 50% -50%;
    width: 1078px;
    z-index: 0;
  }
  @media screen and (min-width:900px) {
    &::before {
      scale: .45;
    }
  }
  @media screen and (min-width:1400px) {
    &::before {
      scale: .75;
    }
  }
  &.circle--left::before {
    left: 0;
    right: auto;
    translate: -50% -5%;
  }
  &.circle--full {
    &::before {          
      left: 0;
      right: auto;
      scale: .6;
      translate: -30% -20%;
    }
    @media screen and (min-width:900px) {
      &::before {
        scale:  1;
        translate: 0% -10%;
      }
    }
  }
}
.quiz {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
  transition: opacity .5s;

  @media screen and (min-width:768px) {
    gap: 40px;
    grid-template-columns: max-content 1fr;
  }
  .imgWrap {
    max-width: 395px;
    position: relative;

    @media screen and (min-width:768px) {
      box-shadow: 0 0 20px rgba(0, 0, 0, .3);
      height: 395px;
      width: 395px;
    }
    @media screen and (min-width:1200px) {
      height: 550px;
      max-width: none;
      width: 550px;
    }
  }
  .formWrap {
    align-content: end;
    font-size: 24px;

    & p + p {
      margin-top: 30px;
    }
    & button {      
      appearance: button;
      border: 0;
      border-radius: 32px;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      color: var(--d6-pink);
      cursor: pointer;
      font-size: 20px;
      height: 48px;
      padding: 0px 20px;

      & + button {
        margin-left: 15px;
      }

      @media screen and (min-width:1200px) {
        font-size: 26px;
        height: 64px;
        padding: 0px 40px;

        button + button {
          margin-left: 30px;
        }
      }
    }

    @media screen and (min-width:900px) {
      font-size: 36px;
    }
    @media screen and (min-width:1200px) {
      font-size: 50px;
    }
  }
  &.fade {
    opacity: 0;
  }
}
.resultWrap {
  transition: opacity .5s;

  &.fade {
    opacity: 0;
  }
  & h1 {    
    line-height: 170px;
    text-align: left;

    @media screen and (min-width:900px) {
      line-height: 300px;
      text-align: right;
    }
    @media screen and (min-width:1200px) {
      line-height: 400px;
    }
  }
}
h2 {
  font-size: 48px;
  
  @media screen and (min-width:900px) {
    align-items: end;
    display: flex;
    font-size: 52px;
    height: 115px;
    justify-content: end;
  }
}
.scoreWrap {
  display: block;
  font-size: 72px;
  line-height: 72px;
  margin-top: 40px;

  @media screen and (min-width:900px) {
    font-size: 96px;
    line-height: 96px;
    margin-top: 0;
  }
}
.summarySpan {
  display: block;
  font-size: 32px;
  margin-top: 40px;

  @media screen and (min-width:900px) {
    text-align: right;
  }
}
.final {
  margin: 0 auto;
  position: relative;
  z-index: 1;

  & p {
    font-size: 28px;
    margin-bottom: 30px;
    max-width: 940px;
  }
  @media screen and (min-width:900px) {
    align-items: center;
    display: grid;
    justify-content: center;
    height: 100%;
    
    & p {
      font-size: 52px;
    }
  }
}
.restart {
  color: white;
  font-size: 28px;
  position: relative;
  width: max-content;

  & span {
    position: absolute;
    right: -50px;
  }
  &:active,
  &:hover {
    color: white;
  }
  @media screen and (min-width:900px) {
    margin-top: 40px
  }
}
.centered h1 {
  font-size: 80px;
  text-align: center;
}